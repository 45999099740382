<template>
  <div class="albums-page">
    <h1>Collect with albums</h1>
    <Albums></Albums>
  </div>
</template>

<script>
import Albums from "truefuture-ui/src/components/market/dashboard/albums/Albums.vue";

export default {
  name: 'albums-page',
  components: {Albums},
}
</script>

<style lang="less">
.albums-page {
  background: #031337;
  color: #FFF;
  > h1 {
    width: var(--main-width-full);
    margin: 50px auto 20px;
    font-weight: 700;
    font-size: 46px;
    line-height: 69px;
  }
  .container {
    width: 1180px;
    margin: 0 auto;
  }
  .mobile {
    display: none;
  }

  @media screen and (max-width: 1439px) {
    .container {
      width: 850px;
    }
  }
  @media screen and (max-width: 1023px) {
    .container {
      width: 670px;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      width: 100%;
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 429px) {
    .container {
      width: 100%;
      padding: 0 15px;
    }
  }
}
</style>